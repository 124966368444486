import { Link } from "react-router-dom"
import { ajustes } from "../tools/tools"

const Curso = ({data}) => {
    console.log(data)
    return (
        <div className="flex flex-col rounded-2xl w-96 bg-[#ffffff] shadow-xl">
            <figure className="flex justify-center items-center rounded-2xl w-full h-full">
                <img className='rounded-t-2xl object-cover w-full h-full' src={data.imagen} alt={data.titulo}/>
            </figure>
            <div className="flex flex-col p-8">
                <div className="text-2xl font-bold   text-[#374151] pb-6">{data.nombre}</div>
                <div className=" text-lg   text-[#374151]">{data.descripcion}</div>
                <div className="flex justify-end pt-6">
                    <Link key={data.id} to={`/cursos/curso/hide/${data.categoria_id}/${data.id}`}>
                        <button className="bg-[#888888] text-[#ffffff] font-bold text-base p-3 rounded-lg hover:bg-gray-600 active:scale-95 transition-transform transform">Ver curso</button>
                    </Link>
                </div>
            </div>
        </div>
    )
  }
  export default Curso









import React from "react"
import { Link } from "react-router-dom"
import ThemeSelector from "../components/ThemeSelector/ThemeSelector"
import BottomMenu from "../components/BottomMenu"

const PageLayout = ({ children }) => {
  return (
    <div className="categoria">
      <header className="absolute top-0 left-0 w-full flex flex-1 px-2 pt-2">
        <div id='main-logo'><Link to="/"><img src='logo192.png' alt='' title=''/></Link></div>
        <div className="grow flex justify-end"><ThemeSelector /></div>
      </header>
      <main>{children}</main>
      <footer className="absolute bottom-0 left-0 w-full flex flex-1 px-2 pb-2"><BottomMenu /></footer>
    </div>
  )
}

export default PageLayout
import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom';
import PageLayout from "../pages/PageLayout"
import Curso from "./Curso";
import { ajustes } from "../tools/tools";


const CursosCategoria = () => {
    const { id } = useParams();
    const [cursos, setCursos] = useState([])
    
    useEffect(()=>{
        fetch(`${ajustes.baseUrl}/cursos/categoria/hide/${id}`)
        .then(response=>{
            if(response.status===200){
                return response.json()
            }else{
                alert(response.status)
            }
        })
        .then(respuesta=>{
            console.log(respuesta)
            setCursos(respuesta)
        })
        .catch(error=>console.log(error))
    },[])



    return (
        <PageLayout>
            <main className="p-4 flex justify-center">
                <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 px-4 py-3">
                    {cursos.map(elemento=>{
                        console.log(elemento)
                        return <Curso id={elemento.id} data={elemento}/>
                    })}
                </div>
            </main>
        </PageLayout>
    )
}
export default CursosCategoria
import { useEffect, useState } from "react";
import { WebContext } from "./context/WebContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NavLink } from "react-router-dom";
import './css/app.css';


import Home from "./pages/Home";
import Web from "./pages/Web"
import Juegos from "./pages/Juegos"
import Cursos from "./pages/Cursos"
import Contacto from "./pages/Contacto";
import NoPage from "./pages/NoPage";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import CursosCategoria from "./components/CursosCategoria";
import CursoLinks from "./components/CursoLinks"

function App() {
  const [webSettings, setWebSettings] = useState({theme:'dark'})
  const miRoot = document.querySelector('#root')

  useEffect(()=>{
    miRoot.classList.toggle('root-light-mode')
  }, [webSettings])
    
  return (
      <WebContext.Provider value={{webSettings, setWebSettings}}>
      <BrowserRouter>
        <Routes>
            <Route index element={<Home/>} />
            
              <Route path="web" element={<Web/>} />
              <Route path="juegos" element={<Juegos/>} />
              <Route path="cursos/categoria/nojwt/:id" element={<CursosCategoria/>} />
              <Route path="cursos/curso/hide/:categoria/:id" element={<CursoLinks/>} />
              <Route path="cursos" element={<Cursos/>} />
              <Route path="contacto" element={<Contacto />} />
              <Route path="login" element={<Login />} />
              <Route path="admin" element={<Admin />} />
              <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </WebContext.Provider>
  );
}
export default App;
import { useState, useEffect, useRef } from "react"
import { FaShuffle } from "react-icons/fa6"
import { IoLinkSharp } from "react-icons/io5";
import DOMPurify from 'dompurify'

const Proyecto = ({ data, cb }) => {
    const divRef = useRef(null);
    const [clasesDiv3, setClasesDiv3] = useState('flex justify-center items-center sm:self-end')
    const [clasesDiv4, setClasesDiv4] = useState('descripcion flex gap-4 flex-col self-start')
    const handleRefresh = ()=>{
        /* window.location.reload(); */
        cb()
        setTimeout(()=>{
          divRef.current.scrollTo({
            top: 0,
            behavior: "smooth", // para hacer el scroll suave
          });
        }, 1000)
    }

  useEffect(() => {
    
    //const aleatorio = Math.random()
    /* if (aleatorio < 0.5) {
      setClasesDiv3('self-start')
      setClasesDiv4('flex gap-4 flex-col self-end')
    } */
    ///////////////////////////////////////////////////////////////////////   

  }, [])

  if (!data) return null

  return (
    <article className="grid overflow-y-auto sm:overflow-y-visible proyecto gap-4" ref={divRef}>
      <div className="flex justify-center items-center titulo">
        <h2 className="text-6xl sm:text-9xl">{data.titulo}</h2>
      </div>
      <div className="flex justify-center items-center">
        <img className='imgFadeIn' src={`http://localhost:3000/assets/portfolio/${data.imagen1}`} alt={data.titulo} />
      </div>
      <div className={clasesDiv3}>
        <img className='imgFadeIn object-contain h-auto w-full sm:w-auto' src={`http://localhost:3000/assets/portfolio/${data.imagen2}`} alt={data.descripcion} />
      </div>
      <div className={clasesDiv4}>
        {/* <p>{data.descripcion}</p> */}
        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.descripcion) }} />
        <p className="hoverFade text-right font-bold link">
          {data.link ?
            (<a className='flex justify-end' target='_blank' rel="noopener noreferrer" href={data.link}><IoLinkSharp  size={40}/></a>)
          :
            null          
          }          
        </p>
        <button className="hoverFade self-end" onClick={handleRefresh}><FaShuffle size={32}/></button>
      </div>
    </article>
  )
}

export default Proyecto
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import SaveIcon from '@mui/icons-material/Save';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState } from 'react';
import SkillsIcon from '../SkillsIcon';

const AdminItem = ({item}) => {
    const [descripcion, setDescripcion] = useState(item.descripcion)
    const showConfirm = (e)=>{
        e.stopPropagation();
        const confirmButton = e.target.closest('.botoneraItem').querySelectorAll('button')[1]; // Selecciona el segundo botón
        confirmButton.classList.remove('hidden'); // Hace el toggle de la clase 'hidden'

        const deleteButton = e.target.closest('.botoneraItem').querySelectorAll('button')[0]; // Selecciona el segundo botón
        //deleteButton.classList.add('hidden'); // Hace el toggle de la clase 'hidden'        
    }
    const showEditArea = (e)=>{
        const editArea = e.target.closest('article').querySelectorAll('div')[e.target.closest('article').querySelectorAll('div').length - 1];
        editArea.classList.remove('hidden'); // Remueve la clase 'hidden' del último div
    }
    const hideEditArea = (e)=>{
        const editArea = e.target.closest('article').querySelectorAll('div')[e.target.closest('article').querySelectorAll('div').length - 1];
        editArea.classList.add('hidden'); // Remueve la clase 'hidden' del último div
    }
    return (
        <article className="w-full flex flex-1 flex-col w-full max-w-5xl p-4 gap-4 rounded-xl adminArticle">
            <div className='flex'>
                <div className="input-group">
                    <label for="">Título</label>
                    <p className="font-bold flex justify-center items-center">{item.titulo}</p>
                </div>
                <div className="input-group grow text-center">
                    <label for="">Descripción</label>
                    <p className="font-bold flex justify-center items-center grow">{item.descripcion.slice(0, 50)}...</p>

                </div>
                <div className="botoneraItem flex flex-1 grow justify-end gap-4">
                    <button className="botonAdmin bg-orange-400 justify-center uppercase flex" onClick={showConfirm}><HighlightOffIcon/></button>
                    <button className="botonAdmin bg-red-400 hidden justify-center uppercase flex"><DeleteIcon/></button>
                    <button className="botonAdmin bg-green-300 justify-center uppercase flex" onClick={showEditArea}><EditNoteIcon/></button>
                </div>
            </div>
            <div className="editArea hidden rounded-lg rounded-t-none relative">
                
                <div className="campos p-4 grow flex gap-4">
                    <form action="">
                        <div className="fila flex mb-8 gap-4">
                            <div className="input-group">
                                <label for="">Categoría</label>
                                <select name="categoria" className="p-2 rounded-lg">
                                    <option value="1">WEB</option>
                                    <option value="2">GAMES</option>
                                    <option value="3">CURSOS</option>
                                </select>
                            </div>
                            <div className="input-group grow">
                                <label for="">Título</label>
                                <input type="text" name='titulo' className='titulo p-2 rounded-lg' placeholder='título' maxLength='30'/>    
                            </div>

                            <div className="input-group">
                                <label for="">Link</label>
                                <input type="text" name='link' className='link p-2 rounded-lg' placeholder='link' maxLength='255' value={item.link}/>
                            </div>
                        </div>

                        <div className="fila flex mb-8 gap-4">
                            <div className="input-group">
                                <label for="">Descripción</label>
                                <ReactQuill theme="snow" value={descripcion} onChange={setDescripcion} />
                            </div>
                            <div className="input-group">
                                <label for="">Imagen</label>
                                <div className="imagen">
                                    <img src={item.imagen} alt={item.descripcion} />
                                </div>
                            </div>

                            <div className="iconos input-group flex flex-col">
                                <label for="">Skills</label>
                                <div className='flex flex-col gap-2 grow'>
                                    <SkillsIcon data={{categoria:1, estado:0}} key={1}/>
                                    <SkillsIcon data={{categoria:2, estado:0}} key={2}/>
                                    <SkillsIcon data={{categoria:3, estado:1}} key={3}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>               
                <footer className='mt-4 mb-4 w-full min-h-min flex gap-4 justify-center'>
                    <button className="botonAdmin botonAdminSmall bg-gray-300 justify-center " onClick={hideEditArea}><DisabledByDefaultIcon/></button> 
                    <button className="botonAdmin botonAdminSmall bg-green-300 justify-center" onClick={showEditArea}><SaveIcon/></button> 
                </footer>
            </div>
        </article>
    )
}
export default AdminItem
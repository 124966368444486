import { NavLink, Outlet } from "react-router-dom"
import ThemeSelector from "../components/ThemeSelector/ThemeSelector"
import { useState, useEffect } from "react"
import {getRandomFromArray} from '../tools/tools'

import { IoLogoReact } from "react-icons/io5";
import { FaNode } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { SiMysql } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io5";
import { SiTailwindcss } from "react-icons/si";
import { IoLogoGithub } from "react-icons/io5";
import { IoLogoWordpress } from "react-icons/io5";


const Home = () => {
    const cosas = ['websites', 'backends', 'frontends', 'juegos', 'apis', 'cursos', 'webapps' ]
    const [cosa, setCosa] =useState('cosas')
    useEffect(()=>{
        const iconos = document.querySelectorAll('#skills svg')
        iconos.forEach(icono=>{
            icono.style.opacity=0
        })
        iconos.forEach((icono, index) => {
            setTimeout(() => {
              //icono.style.transition = "opacity 0.5s"
              icono.style.opacity = 1
            }, index * 250)
        });
        setTimeout(()=>{
            const navegacion = document.querySelector('#homeNav')
            navegacion.style.top='0'
            navegacion.style.opacity=1
        }, 3000)
        var intervalo = setInterval(()=>{
            setCosa(getRandomFromArray(cosas, cosa))
        }, 1500)
        // Limpiar el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalo);
    }, [])


    return (
        <div id='homepage' className="flex flex-col gap-8 p-8 sm:p-0">
            <section id="presentacion" className="">
                <h3 className="opacity-60">Hola, me llamo</h3>
                <h1 className="text-5xl">Jorge Enríquez</h1>
                <h2 className="opacity-70 font-bold text-3xl">y hago <span>{cosa}</span> para la web.</h2>
                <div id="skills" className="mt-2 flex gap-4 hidden sm:flex">
                    <IoLogoJavascript size={24}/>
                    <IoLogoReact size={24}/>
                    <FaNode size={24}/>
                    <SiExpress size={24}/>
                    <SiMysql size={24}/>
                    <SiTailwindcss size={24}/>
                    <IoLogoGithub size={24}/>
                    <IoLogoWordpress size={24}/>
                </div>
            </section>
            <nav id="homeNav" className='flex flex-col relative'>
                <NavLink to="/web" className="hoverFade py-0 leading-none roboto-black homeLink">web</NavLink>
                <NavLink to="/juegos" className="hoverFade py-0 leading-none roboto-black homeLink">juegos</NavLink>
                <NavLink to="/cursos" className="hoverFade py-0 leading-none roboto-black homeLink">cursos</NavLink>
                <NavLink to="/contacto" className="hoverFade py-0 leading-none roboto-black homeLink">contacto</NavLink>
            </nav>
            <div className='absolute right-4 top-4'><ThemeSelector/></div>
            <Outlet />
        </div>
    )
}
export default Home
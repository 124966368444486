import { useState } from "react";
import { PulseLoader } from "react-spinners";
import { ajustes } from "../tools/tools";
import { Link } from "react-router-dom";
import CursosFicha from "./CursosFicha";

const FormCursos = () => {
	const handleForm = (e) => {
        e.preventDefault()
		setFormVisible(false)
        setWaiting(true)
        const dataEnviada = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                pwd: document.querySelector('#password').value
            }),
        };
        fetch(`${ajustes.baseUrl}/cursos/categorias`, dataEnviada)
        .then(response=>{
            setWaiting(false)
            const estado = response.status
            if(response.status!==200){
                setFormVisible(true)
            }
            return response.json().then(data => ({ estado, data })); // Retornar el status y el JSON
        })
        .then(({ estado, data }) => {
            console.log(estado)
            if(estado===200){
                setContenido(data)
            }else{
                setEstatus(data.msg)
                setTimeout(()=>{
                    setEstatus('')
                }, 3000)
            }
        })
        .catch(error=>console.log(error))
	};
    const override: CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
      };
    
    const [formVisible, setFormVisible] = useState(true)
    const [waiting, setWaiting] = useState(false)
    const [estatus, setEstatus] = useState('')
    const [contenido, setContenido] = useState(['<h1>Contenido</h1>'])

	return (
        <div className='flex flex-col'>
        {
            formVisible ? 
                <form className="form" onSubmit={handleForm}>
                    <div className="input-group">
                        <input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Escribe la contraseña..."
                            maxLength={10}
                            required
                        />
                    </div>
                    <button className="mt-8 sign btn-estandar color-white">ENVIAR</button>
                    {estatus==='' ? 
                        null                  
                    :
                        <div className="mt-8 p-4 text-sm font-bold text-center text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                            {estatus}
                        </div>
                    }
                </form>
            :
                waiting ?
                    <PulseLoader loading={!formVisible} size={50} color='#888' cssOverride={override} size={20} aria-label="Loading Spinner" data-testid="loader"/>
                :
                    
                    <div className="grid grid-cols-1 xl:grid-cols-3 gap-2 px-4 py-3  ">
                        
                        {contenido.map(elemento=>{
                            console.log(elemento)
                            return(
                                <Link to={`/cursos/categoria/nojwt/${elemento.id}`}>
                                    
                                    <button className="p-4 bg-gray-500 rounded-xl">
                                        <CursosFicha key={elemento.id} data={elemento}/>
                                    </button>
                                </Link>
                            )
                        })}
    
                    </div>
            }     
            
        </div>
	);
};
export default FormCursos;
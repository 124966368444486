import { NavLink } from "react-router-dom"
const BottomMenu = () => {
    const miUrl = window.location.href || "/"
    return (
        <nav id="bottomNav" className='flex w-full'>
            <NavLink to="/web" className={miUrl.endsWith('web') ? "hidden" : "grow text-center uppercase roboto-black"}>web</NavLink>
            <NavLink to="/juegos" className={miUrl.endsWith('juegos') ? "hidden" : "grow text-center uppercase roboto-black"}>juegos</NavLink>
            <NavLink to="/cursos" className={miUrl.endsWith('cursos') ? "hidden" : "grow text-center uppercase roboto-black"}>cursos</NavLink>
            <NavLink to="/contacto" className={miUrl.endsWith('contacto') ? "hidden" : "grow text-center uppercase roboto-black"}>contacto</NavLink>
        </nav>
    )
}
export default BottomMenu
import { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { ajustes } from "../tools/tools"

const CursoLinks = (data) => {
    const {id} = useParams()
    const {categoria} = useParams()
    console.log(categoria)
    const [enlaces, setEnlaces] = useState([])
    useEffect(()=>{
        fetch(`${ajustes.baseUrl}/cursos/curso/hidecourse/${id}`)
        .then(response=>{
            if(response.status===200){
                return response.json()
            }else{
                alert(response.status)
            }
        })
        .then(respuesta=>{
            //console.log(respuesta)
           setEnlaces(respuesta)
        })
        .catch(error=>console.log(error))
        ////////////////////////////////////////////////////////////////
        fetch(`${ajustes.baseUrl}/cursos/categoria/hide/${categoria}`)
        .then(response=>{
            console.log(response)
            return response.json()
        })
        .then(respuesta=>console.log(respuesta))

    },[])
    return (
        <div className="flex flex-col rounded-2xl w-96 bg-[#ffffff] shadow-xl">
            <figure className="flex justify-center items-center rounded-2xl">
                <img src="https://tailwind-generator.b-cdn.net/images/card-generator/tailwind-card-generator-card-preview.png" alt="Imagen del curso" className="rounded-t-2xl"/>
            </figure>
            <div className="flex flex-col p-8">
                <div className="text-2xl font-bold   text-[#374151] pb-6">Título del curso</div>
                <div className=" text-lg   text-[#374151]">Descripción del curso</div>
                <div className="flex flex-col justify-end pt-6 text-black">
                    {/* <button className="bg-[#888888] text-[#ffffff]  font-bold text-base  p-3 rounded-lg hover:bg-purple-800 active:scale-95 transition-transform transform">Atrás</button> */}
                    {enlaces.map((enlace, idx)=>{
                        return <Link key={enlace.id} to={enlace.url} target="_blank">{idx+1}- {enlace.texto}</Link>
                    })}
                </div>
            </div>
        </div>
            
    )   
  }
  export default CursoLinks
/* export const getRandomFromArray = (array)=>{
    const random = Math.floor(Math.random() * array.length);
    return array[random]
} */
export const getRandomFromArray = (array, exclude = null)=>{
    let randomItem;
    do {
        randomItem = array[Math.floor(Math.random() * array.length)];
    } while (exclude !== null && randomItem === exclude);
    return randomItem;
}
export const ajustes = {
    /*baseUrl:'http://localhost:3001'*/
    baseUrl:'https://kpuqykhp.lucusprueba.es/api/jorgeenriquez'
}
import { useState } from "react";
import { Link } from "react-router-dom";
import PageLayout from "./PageLayout"
import { IoLogoLinkedin } from "react-icons/io5";
import { IoLogoGithub } from "react-icons/io5";
import { IoLogoSkype } from "react-icons/io5";
import { BiLogoZoom } from "react-icons/bi";
import { WiSmoke } from "react-icons/wi";
import { LuBird } from "react-icons/lu";


const Contacto = () => {
    const [icon, setIcon] = useState(<WiSmoke size={32}/>)
    const [errorMsg, setErrorMsg] = useState('Señales de humo\ntemporarily disabled.')
    if(Math.random()<.5){
        setIcon(<LuBird  size={28}/>)
        setErrorMsg('Turbo Palomas mensajeras\ntemporarily disabled.')
    }
    return (
        <PageLayout>
            <main className="p-4 flex justify-center ">
                <section id='contacto' className="flex flex-col items-center gap-4">
                    <p className="text-xl superDestacado hoverFade">hola@jorgeenriquez.com</p>
                    <div className="flex gap-4">
                        <Link className='hoverFade' to='https://www.linkedin.com/in/jorge-enr%C3%ADquez-52207920/' target="_blank" rel="noopener noreferrer"><IoLogoLinkedin size={32}/></Link>
                        <Link className='hoverFade' to='https://github.com/jorgeenriquezm' target="_blank" rel="noopener noreferrer"><IoLogoGithub size={32}/></Link>
                        <Link className='hoverFade' to='https://join.skype.com/invite/KbwK0cgyUqGb' target="_blank" rel="noopener noreferrer"><IoLogoSkype size={32}/></Link>
                        <Link className='hoverFade' to='https://us05web.zoom.us/profile?amp_device_id=44c81b19-d061-4994-879f-55ba227a4e3e' target="_blank" rel="noopener noreferrer"><BiLogoZoom size={32}/></Link>

                        <div className="hidden sm:flex flex flex-col items-center gap-2">
                            <div className="group relative">
                                <div>{icon}</div>
                                <div
                                className="bg-red-800 p-2 rounded-md group-hover:flex flex-col hidden absolute -bottom-2 translate-y-full left-1/2 -translate-x-1/2 font-serif"
                                >
                                <span className="text-red-400 whitespace-nowrap">503 - Service Unavailable</span>
                                <p className="text-red-300 whitespace-nowrap">
                                    {errorMsg.split('\n').map((line, index) => (
                                        <span key={index}>
                                            {line}<br />
                                        </span>
                                    ))}
                                </p>
                                <div
                                    className="bg-inherit rotate-45 p-1 absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2"
                                ></div>
                                </div>
                            </div>
                        </div>
                    </div>                 
                </section>
            </main>
    </PageLayout>
    )
}
export default Contacto
import { useState, useEffect } from "react"
import Proyecto from "../components/Proyecto"
import PageLayout from "./PageLayout"
import FormCursos from "../components/FormCursos"
import { ajustes, getRandomFromArray } from '../tools/tools'

const Cursos = () => {
  const [proyectos, setProyectos] = useState([])
  const [proyecto, setProyecto] = useState(null)

  useEffect(() => {
    fetch(`${ajustes.baseUrl}/portfolio/games`)
      .then(response => {
        if (response.status === 200) {
          return response.json()
        } else {
          alert('Ha fallado algo en la carga de la información, refrescando...')
          throw new Error('Failed to load data')
        }
      })
      .then(respuesta => {
        setProyectos(respuesta)
        setProyecto(getRandomFromArray(respuesta))
      })
      .catch(error => console.error(error))

  }, [])

  return (
    <PageLayout>
      <main className="p-4 flex justify-center">
        <FormCursos/>
      </main>
    </PageLayout>
  )
}

export default Cursos
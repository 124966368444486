import "../css/reset.css";
import "../css/main.css";
import "../css/app.css";
import "../css/admin.css";
import { ajustes } from "../tools/tools";
import { useEffect, useState } from "react";
import AdminItem from "../components/AdminItem/AdminItem";
import { IoAddCircle } from "react-icons/io5";

const Admin = () => {
    useEffect(()=>{
        fetch(`${ajustes.baseUrl}/portfolio/web`)
        .then(response=>response.json())
        .then(respuesta=>setItems(respuesta))
        .catch(error=>console.log(error))
    }, [])

    const [statusText, setStatusText] = useState('')
    const [items, setItems] = useState([])
    return (
        <main id="contenedor" className="w-full rounded-xl">
            <header className="absolute top-0 left-1/4 w-2/4 flex flex-1 gap-40 pt-6 z-10">
                <button className="botonAdmin justify-center uppercase flex">web</button>
                <button className="botonAdmin justify-center uppercase flex">games</button>
                <button className="botonAdmin justify-center uppercase flex">cursos</button>
            </header>
            <main className="w-full flex flex-col items-center p-4 mt-40">
                <button className="botonAdmin botonAdminGrande bg-green-300 justify-center uppercase flex mb-8">Añadir  proyecto<IoAddCircle/></button>
                {items.map(item=>{
                    console.log(item)
                    return <AdminItem item={item} key={item.id}/>
                })}
            </main>
            <div>{statusText}</div>
		</main>
    )
}
export default Admin
import "../css/reset.css";
import "../css/main.css";
import "../css/app.css";
import "../css/admin.css";
import { ajustes } from "../tools/tools";
const Login = () => {
    const handleForm = (e)=>{
        e.preventDefault()
        const dataEnviada = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: document.querySelector('#username').value,
                pwd: document.querySelector('#password').value
            }),
        };
        fetch(`${ajustes.baseUrl}/login`, dataEnviada)
        .then(response=>{
            //console.log(response.status)
            if(response.status===200){
                return response.json()
            }else{
                //todo hacer algo con los errores
                console.log(response)
            }
        })
        .then(respuesta=>{
            localStorage.setItem('authToken', respuesta.token)
        })
        .catch(error=>console.log(error))
    }
	return (
		<main id="contenedor">
			<div className="form-container">
				<p className="title">Área privada</p>
				<form className="form" onSubmit={handleForm}>
					<div className="mb-4 input-group">
						<input type="text" name="username" id="username" placeholder="email" />
					</div>
					<div className="input-group">
						<input type="password" name="password" id="password" placeholder="pwd"/>
					</div>
					<button className="mt-8 sign">ENTRAR</button>
				</form>
			</div>
		</main>
	);
};
export default Login;

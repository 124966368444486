import { useState, useEffect } from "react";
import { IoLogoJavascript } from "react-icons/io5";
import { IoLogoReact } from "react-icons/io5";
import { IoGameController } from "react-icons/io5";

const SkillsIcon = ({data}) => {
    const verde = 'rgb(134, 239, 172)'
    const rojo = 'rgb(248, 113, 113)'
    const [icono, setIcono] = useState(null)
    const [estado, setEstado] = useState(0)
    const [color, setColor] = useState(null)

    const toggleClick = () => {
        setEstado(prevValor => (prevValor === 0 ? 1 : 0)); // Cambia entre 0 y 1        
    };
    
    useEffect(() => {
        if (data.categoria === 1) {
            setIcono(<IoLogoJavascript size={50} color={ estado===0 ? rojo : verde } onClick={toggleClick}/>);
        } else if (data.categoria === 2) {
            setIcono(<IoLogoReact size={50} color={estado===0 ? rojo : verde} onClick={toggleClick}/>);
        } else {
            setIcono(<IoGameController size={50} color={estado===0 ? rojo : verde} onClick={toggleClick}/>);
        }
    }, [data.categoria, estado, color]);


    useEffect(()=>{
        console.log(data.estado)
        if(data.estado===0){
            setEstado(0)
            setColor(rojo)
        }else{
            setEstado(1)
            setColor(verde)
        }
    }, [data.estado])


    return (
        <div className="skillsIcon">
            <div className="arriba">
                {icono}
            </div>
            <div className="abajo">

            </div>
        </div>
    )
}
export default SkillsIcon